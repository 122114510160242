import React, { useState } from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  BreadCrumb,
  Container,
  Section,
  SectionTitle,
  SectionDescription,
  MarkdownBody,
  MarkdownContent,
  SectionInnerWhite,
} from "../components/Section"
import {
  HeroContainer,
  Hero,
  Background,
  HeroCaption,
  HeroTitle,
  HeroDescription,
} from "../components/Hero"
import DefaultButton from "../components/Button/DefaultButton"
import LineArrowRight from "../components/Icons/LineArrowRight"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import RequestModal from "../components/RequestModal"

const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const GridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
  &:last-child {
    .card {
      &:before {
        ${BreakpointDown.lg`
          display:none;
        `}
      }
      .card-action {
        ${BreakpointDown.lg`
          display:none;
        `}
      }
    }
  }
`
const Card = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  padding: 30px 30px 30px 15px;
  height: calc(100% - 30px);
  background: #fff;
  transition: all 0.3s ease-in-out;
  ${BreakpointUp.xl`
    padding:30px 45px 30px 30px;
  `}
  &:after {
    content: "";
    position: absolute;
    background-color: #fff;
    right: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 30px;
    display: none;
    ${BreakpointUp.lg`
      display:block;
      right: 0;
      top: 0;
      bottom: 0;
      width: 30px;
      height: 100%;

    `}
    ${BreakpointUp.xxl`
      width: 40px;
    `}
  }
  &:before {
    content: "";
    position: absolute;
    top: auto;
    right: auto;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background: #efefef;
    ${BreakpointUp.lg`
      height:10px;
      box-shadow:0 15px 25px rgba(3,51,102,0.20);
      background:transparent;
      top:50%;
      right:0;
      left:auto;
      bottom:auto;
      transform:translateY(-50%);
      width:10px;
      height:80%;
    `}
    ${BreakpointUp.xxl`
      width:15px;
    `}
  }
`
const CardTitle = styled.h3`
  position: relative;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 26px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media (min-width: 1600px) {
    font-size: 24px;
    line-height: 30px;
  }
`
const CardBody = styled.div`
  position: relative;
`
const CardText = styled.p`
  max-width: 720px;
  width: 100%;
  margin: 0 auto 10px auto;
`
const CardFigure = styled.figure`
  position: relative;
  margin: 0 0 20px;
  height: 84px;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`
const CardAction = styled.div`
  position: absolute;
  z-index: 1;
  right: auto;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  ${BreakpointUp.lg`
    top:50%;
    left:auto;
    right:-15px;
    transform:translateY(-50%);
  `}
  ${BreakpointUp.xxl`
    right:-25px;
  `}
  button {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0.5px 0px 0px 0px rgb(3 51 102 / 20%);
    cursor: default;
    width: 40px;
    height: 40px;
    ${BreakpointUp.xxl`
      width:60px;
      height:60px;
    `}
    span {
      width: 0;
      height: 0;
      ${BreakpointUp.lg`
        border-top: 8px solid transparent;
        border-left: 12px solid red;
        border-bottom: 8px solid transparent;
        border-right:none;
      `}
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 12px solid red;
      border-bottom: none;
    }
  }
`
const ExploreMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  width: 100%;
  display: block;
  margin: 20px auto 0 auto;

  ${BreakpointUp.sm` 
        position: absolute;
        left:50%;
        transform:translateX(-50%);
        bottom: -22px;
        margin:0;
    `}
  ${BreakpointUp.lg` 
        bottom: -26px;
    `}
    button, .btn {
    width: 100%;
  }
`
const MediaList = styled.div`
  margin: 0;
  padding: 0;
`
const MediaItem = styled.div`
  padding: 30px 0;
  ${BreakpointUp.xl`
        padding:40px 0;
    `}
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  + div {
    border-top: 1px solid #ccd6e0;
  }
`
const Media = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const MediaImage = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px;
  ${BreakpointUp.sm`
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin-bottom:0;
  `}
  .gatsby-image-wrapper {
    border-radius: 0;
    box-shadow: none;
    width: auto;
  }
`
const MediaBody = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.sm`
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`
const MediaTitle = styled.div`
  color: #000;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 20px;
  line-height: 24px;
  ${BreakpointUp.md`
        font-size: 22px;
        line-height: 28px;
    `}
  ${BreakpointUp.xxl`
        font-size: 24px;
        line-height: 30px;
    `}
`
const MediaDesc = styled.div`
  > p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    + p {
      margin-top: 30px;
    }
  }
`

const WhyChooseUsPage = ({ location }) => {
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)
  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }
  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title="Liberty Metal Structures - Why Choose Us!"
        description="We are an experienced team who provide metal buildings made of American Steel, at the best prices, with easy payment options."
      />
      <Section
        xpt="114px"
        mpt="94px"
        pt="64px"
        xpb="0"
        mpb="0"
        pb="0"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <HeroContainer>
            <Hero>
              <Background>
                <StaticImage
                  src="../images/banner-static-bg.jpg"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="banner-static-bg"
                />
              </Background>
              <HeroCaption>
                <BreadCrumb className="white" position="static" translate="0">
                  <Link to="/">Home</Link>
                  <span>Why Choose Us</span>
                </BreadCrumb>
                <HeroTitle>
                  You Want Liberty! Choose Liberty Metal Structures!
                </HeroTitle>
                <HeroDescription maxWidth="1120px">
                  <p>
                    You deserve the best quality metal building in the industry,
                    and you won’t find better customer service than the
                    committed experts at Liberty.
                  </p>

                  <p>
                    Liberty Metal Structures is proud to be an All-American
                    company. Every one of our metal buildings is engineered and
                    created right here in the United States.
                  </p>

                  <p>
                    At Liberty, we treat every customer as a member of our
                    family. Why? Because our business is owned and operated by
                    our family. When you’re a customer at Liberty, you’ll find
                    that family comes first! We strive for excellence in
                    customer service at every step of the metal building process
                    by following the Business Golden Rule: we treat our
                    customers the way we would want to be treated.
                  </p>

                  <p>
                    Our incredible team offers affordable custom options on all
                    our steel structures, from carports to barns, from utility
                    buildings to warehouses. Whatever your unique needs, our
                    engineers can customize a stylish building to suit you!
                    Then, our finance team will work with you to create a
                    financing plan that meets your budget. You’ll love our
                    same-day, no-credit-check programs!
                  </p>
                </HeroDescription>
              </HeroCaption>
            </Hero>
          </HeroContainer>
        </Container>
      </Section>
      <Section pt="0" xpb="30px" mpb="2px" pb="15px" bgColor="transparent">
        <Container maxWidth="100%" pl="3%" pr="3%">
          <MarkdownBody>
            <MarkdownContent maxWidth="890px">
              <MediaList class="media-list">
                <MediaItem>
                  <Media class="media">
                    <MediaImage>
                      <StaticImage
                        src="../svg/dedicated-team.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Building Delivery "
                      />
                    </MediaImage>
                    <MediaBody class="media-body">
                      <MediaTitle>Experienced & Dedicated Team</MediaTitle>
                      <MediaDesc>
                        <p>
                          Our experts are committed to providing the very best
                          customer service you’ll experience anywhere! Our
                          programs are designed to create an enjoyable
                          experience for every valued client, so you’ll feel
                          like a member of the Liberty family at every step of
                          the process.
                        </p>
                      </MediaDesc>
                    </MediaBody>
                  </Media>
                </MediaItem>
                <MediaItem>
                  <Media class="media">
                    <MediaImage>
                      <StaticImage
                        src="../svg/easy-payment.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Easy Payment Options"
                      />
                    </MediaImage>
                    <MediaBody class="media-body">
                      <MediaTitle>Easy Payment Options</MediaTitle>
                      <MediaDesc>
                        <p>
                          Don’t sweat the small stuff! Our finance team will
                          build a plan to fit your budget. You’ll be approved
                          within minutes with minimal paperwork, and there’s no
                          credit check necessary.{" "}
                        </p>
                      </MediaDesc>
                    </MediaBody>
                  </Media>
                </MediaItem>
                <MediaItem>
                  <Media class="media">
                    <MediaImage>
                      <StaticImage
                        src="../svg/customization.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Customization"
                      />
                    </MediaImage>
                    <MediaBody class="media-body">
                      <MediaTitle>Customization</MediaTitle>
                      <MediaDesc>
                        <p>
                          Your style and taste will be satisfied at every stage.
                          Our custom options can be discussed with our engineers
                          or experimented with our state-of-the-art 3D
                          visualizer. Whatever you’re designing, from a snazzy
                          gym to a luxury home, you’ll find the aesthetic you
                          need at Liberty!
                        </p>
                      </MediaDesc>
                    </MediaBody>
                  </Media>
                </MediaItem>
                <MediaItem>
                  <Media class="media">
                    <MediaImage>
                      <StaticImage
                        src="../svg/usa.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Only U.S. Steel"
                      />
                    </MediaImage>
                    <MediaBody class="media-body">
                      <MediaTitle>Only U.S. Steel</MediaTitle>
                      <MediaDesc>
                        <p>
                          Liberty is proud to support local suppliers and
                          economies by purchasing all our high-quality steel
                          from factories in the United States. You can join us
                          in supporting our national interests by buying steel
                          structures from Liberty.
                        </p>
                      </MediaDesc>
                    </MediaBody>
                  </Media>
                </MediaItem>
                <MediaItem>
                  <Media class="media">
                    <MediaImage>
                      <StaticImage
                        src="../svg/free-delivery.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Free Delivery and Installation"
                      />
                    </MediaImage>
                    <MediaBody class="media-body">
                      <MediaTitle>Free Delivery and Installation</MediaTitle>
                      <MediaDesc>
                        <p>
                          Every make and model is delivered and installed for
                          free by our professional crews. You can have
                          confidence that your new metal building will be
                          certified for long-term structural integrity when it
                          is assembled and sealed by the team at Liberty!
                        </p>
                      </MediaDesc>
                    </MediaBody>
                  </Media>
                </MediaItem>
                <MediaItem>
                  <Media class="media">
                    <MediaImage>
                      <StaticImage
                        src="../svg/customer.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Customer Satisfaction is our Priority"
                      />
                    </MediaImage>
                    <MediaBody class="media-body">
                      <MediaTitle>
                        Customer Satisfaction is our Priority
                      </MediaTitle>
                      <MediaDesc>
                        <p>
                          When you’re at Liberty, you’re family, and family
                          comes first! Nothing is more important to our business
                          model than the total satisfaction of our customers.
                        </p>
                      </MediaDesc>
                    </MediaBody>
                  </Media>
                </MediaItem>
              </MediaList>
            </MarkdownContent>
          </MarkdownBody>
        </Container>
      </Section>
      <Section
        xpt="30px"
        mpt="20px"
        pt="15px"
        xpb="90px"
        mpb="90px"
        pb="50px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionInnerWhite pl="0" mpl="0" xpl="0" pr="0" mpr="0" xpr="0">
            <Container maxWidth="100%" pl="3%" pr="3%">
              <SectionTitle>Our Quick & Easy Buying Process</SectionTitle>
              <SectionDescription mb="30px" maxWidth="1496px">
                <p>
                  Liberty is committed to providing a seamless and stress-free
                  process to every customer. You’ll be approved within minutes!
                  Then, you will have a team of finance experts ready to build a
                  plan to meet your budget. Don’t worry – you’re at Liberty!
                </p>
              </SectionDescription>
            </Container>
            <GridRow>
              <GridCol>
                <Card className="card">
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../svg/building-style-size.svg"
                      placeholder="blurred"
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Find a Building Style and Size"
                    />
                  </CardFigure>
                  <CardBody className="card-body">
                    <CardTitle className="h5">Style & Size</CardTitle>
                    <CardText>
                      You have the power to choose a building that’s right for
                      you! Our incredible array of steel structures can
                      accommodate everything from a compact workspace to a
                      soaring arena.
                    </CardText>
                  </CardBody>
                  <CardAction className="card-action">
                    <button type="button" aria-label="button">
                      <span></span>
                    </button>
                  </CardAction>
                </Card>
              </GridCol>
              <GridCol>
                <Card className="card">
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../svg/customise-according-requirement.svg"
                      placeholder="blurred"
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Customise According to Your Requirment"
                    />
                  </CardFigure>
                  <CardBody className="card-body">
                    <CardTitle className="h5">Customize</CardTitle>
                    <CardText>
                      You are the designer at Liberty! With over a dozen color
                      options, wainscoting, gables, and trim, you can please
                      every taste and style.
                    </CardText>
                  </CardBody>
                  <CardAction className="card-action">
                    <button type="button" aria-label="button">
                      <span></span>
                    </button>
                  </CardAction>
                </Card>
              </GridCol>
              <GridCol>
                <Card className="card">
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../svg/request-quote.svg"
                      placeholder="blurred"
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Request a Quote for Your Dream Building"
                    />
                  </CardFigure>
                  <CardBody className="card-body">
                    <CardTitle className="h5">Request Your Quote</CardTitle>
                    <CardText>
                      Call our fantastic team and get a no-hidden-fees quote!
                      We’ll talk you through the custom options, how they affect
                      your estimate, and give you accurate lead times on your
                      potential build.
                    </CardText>
                  </CardBody>
                  <CardAction className="card-action">
                    <button type="button" aria-label="button">
                      <span></span>
                    </button>
                  </CardAction>
                </Card>
              </GridCol>
              <GridCol>
                <Card className="card">
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../svg/delivery-lead-time.svg"
                      placeholder="blurred"
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Building Delivery "
                    />
                  </CardFigure>
                  <CardBody className="card-body">
                    <CardTitle className="h5">Lead Time</CardTitle>
                    <CardText>
                      Your time is money! At Liberty, we provide accurate,
                      reliable lead times. Most of our buildings will be
                      delivered to your doorstep between 3 to 8 weeks from
                      purchase.
                    </CardText>
                  </CardBody>
                  <CardAction className="card-action">
                    <button type="button" aria-label="button">
                      <span></span>
                    </button>
                  </CardAction>
                </Card>
              </GridCol>
              <GridCol>
                <Card className="card">
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../svg/got-building-door.svg"
                      placeholder="blurred"
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Now Got Your "
                    />
                  </CardFigure>
                  <CardBody className="card-body">
                    <CardTitle className="h5">Installation</CardTitle>
                    <CardText>
                      Your beautiful new metal building will be delivered and
                      installed for free! At Liberty, we can’t wait to make our
                      customers smile!
                    </CardText>
                  </CardBody>
                  <CardAction className="card-action">
                    <button type="button" aria-label="button">
                      <span></span>
                    </button>
                  </CardAction>
                </Card>
              </GridCol>
            </GridRow>
          </SectionInnerWhite>
          <ExploreMore>
            <button type="button" aria-label="button" onClick={HandleModalOpen}>
              <DefaultButton
                text="Now Request a Quote"
                icon={<LineArrowRight />}
                size="lg"
              />
            </button>
          </ExploreMore>
        </Container>
      </Section>
      <RequestModal
        location={location}
        isVisible={isQuoteVisible}
        onClose={HandleModalClose}
      />
    </Layout>
  )
}

export default WhyChooseUsPage